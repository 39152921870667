import { Injectable, inject } from '@angular/core'
import { Subject } from 'rxjs'
import { OrderStateService } from '../../../store/state/order/order.state.service'
export interface MenuChangeEvent {
    key: string
    routeEvent?: boolean
}
@Injectable({
    providedIn: 'root'
})
export class AppMenuService {

    orderStateService = inject(OrderStateService)

    private menuSource = new Subject<MenuChangeEvent>()
    private resetSource = new Subject()

    menuSource$ = this.menuSource.asObservable()
    resetSource$ = this.resetSource.asObservable()

    onMenuStateChange(event: MenuChangeEvent) {
        this.menuSource.next(event)
        this.resetState()
    }

    reset() {
        this.resetSource.next(true)
    }

    resetState() {
        this.orderStateService.reset()
    }
}
